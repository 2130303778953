import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: 'auto',
        backgroundColor: 'primary.main',
        color: 'white',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1">
            &copy; {new Date().getFullYear()} CheeseTax. All rights reserved.
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Link href="/about" color="inherit" sx={{ mx: 1 }}>
              About Us
            </Link>
            <Link href="/contact" color="inherit" sx={{ mx: 1 }}>
              Contact
            </Link>
            <Link href="/tos" color="inherit" sx={{ mx: 1 }}>
              Terms and Conditions
            </Link>
            <Link href="/privacy-policy" color="inherit" sx={{ mx: 1 }}>
              Privacy Policy
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
